













































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IInvoiceDetails } from '../interfaces/invoiceDetails';
import moment from 'moment';
import { GtagHelper } from '../utilities/GtagHelper';


const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Invoices = namespace("Invoices");

@Component
export default class InvoicesTable extends Vue {

    public unwatch: any;
    private loading = true;
    private currentPage = 1;
    private perPage = 10;
    private progressBarHidden = false;

    private GtagHelper = new GtagHelper();

    public invoiceFields = [
        {
            key: 'InvoiceDate',
            label: 'Date issued',
            formatter: this.formatDate
        },
        {
            key: 'InvoiceNo',
            label: 'Invoice number'
        },
        {
            key: 'InvoiceTotal',
            label: 'Amount for selected agreement',
            formatter: this.formatAmount
        },
        {
            key: 'DownloadLink',
            label: 'Download'
        }];

    get invoiceCount() {
        if (this.Invoices == null) { return 0; }
        else return this.Invoices.length;
    }

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Invoices.Getter
    private Invoices!: IInvoiceDetails[];

    @Invoices.Action
    private getInvoices!: (params: { accountNum: string, siteRefNum: string, userId: string }) => Promise<void>;

    @Invoices.Action
    private invoiceDownload!: (params: { accountNum: string, invoiceNo: string, userId: string }) => Promise<void>;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    private async onClick(invoiceNo) {
        this.$refs[invoiceNo]!['$el'].style.display = 'flex';
        const accountNum = this.getSelectedAccount;
        const userId = this.getUserId;
        this.invoiceDownload({ accountNum, invoiceNo, userId }).then(() => {
            this.fileProcess();
            this.$refs[invoiceNo]!['$el'].style.display = 'none';
        }).catch(reason => {
            console.error(reason);
            this.$refs[invoiceNo]!['$el'].style.display = 'none';
        });
    }

    private fileProcess() {

        if (this.getDownloadFileData == null) {
            return null;
        }

        var fileName = this.getDownloadFileData.fileName;

        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }

        this.GtagHelper.Event("LumiDownloadInvoice", this.getUserId);
    }

    mounted() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedDeliveryAgreementNumber"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;
                    this.currentPage = 1;

                    const siteRefNum = this.getSelectedSiteRefNumber;
                    const accountNum = this.getSelectedAccount;
                    const userId = this.getUserId;

                    if (siteRefNum !== null) {
                        this.getInvoices({ accountNum: accountNum, siteRefNum: siteRefNum, userId: userId }).then(() => {
                            this.loading = false;
                        }).catch(reason => {
                            this.loading = false;
                            console.error(reason);
                        });
                    }

                    this.GtagHelper.Event("gaLoadInvoiceTable", this.getUserId);
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }

        //this.LoadWistiaVideoPlayer();

    }

    beforeDestroy() {
        this.unwatch();
    }

    public formatAmount(value: number): string {
        if (value === null) {
            return "";
        }        

        var formattedAmount = "£" + this.$options?.filters?.toCurrency(value).replace("-", "");
        if (value < 0) {
            formattedAmount = "-" + formattedAmount + " CR";
        }
        return formattedAmount;
    }

    public formatDate(value: string): string {
        if (value === null) {
            return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }

    public invoiceUnavailable(invoiceDate: string): boolean {

        const result = moment(invoiceDate).isBefore('01 Jan 2014');

        return result;
    }

    public LoadWistiaVideoPlayer() {
        try {

            let wistiaScript_code = document.createElement('script');
            wistiaScript_code.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js');
            document.head.appendChild(wistiaScript_code);

            let wistiaScript_data = document.createElement('script');
            wistiaScript_data.setAttribute('src', 'https://fast.wistia.com/embed/medias/k52jh4zjof.jsonp');
            document.head.appendChild(wistiaScript_data);

        } catch (e) {
            console.error(e);
        }
    }

}
